import React from 'react';
import { Testimonial } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import GenievisionLogo from '@/assets/img/logos-clients/light-mode/genie-vision.svg';
import GenievisionLogoDark from '@/assets/img/logos-clients/dark-mode/genie-vision.svg';

export default function GenieVision(): Testimonial {
  const data = useStaticQuery(graphql`
    query {
      picture: file(
        relativePath: { eq: "projects/genie-vision/caroline-zigrand.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    fullName: 'Caroline Zigrand',
    job: 'testimonialJobGenievision',
    company: 'GenieVision',
    companyLogo: <GenievisionLogo />,
    companyLogoDark: <GenievisionLogoDark />,
    picture: data.picture,
    content: 'testimonialContentGenievision',
    link: 'linkedin.com/in/carolinezigrand',
  };
}
